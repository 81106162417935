<template>
  <a-auto-complete
    v-bind="record.options"
    :value="value"
    @change="change"
    @blur="blur"
    @focus="focus"
    @search="search"
    @select="select"
    @dropdownVisibleChange="dropdownVisibleChange"
  />
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'AxAutoComplete',
  mixins: [formEleMixin],
  methods: {
    /**
     * 选中 option，或 input 的 value 变化时，调用此函数
     * @param value
     */
    change (value) {
      this.$emit('change', value, this.record.model)
    },
    /**
     * 失去焦点时的回调
     */
    blur () {
      this.$emit('blur', this.value, this.record.model)
    },
    /**
     * 获得焦点时的回调
     */
    focus () {
      this.$emit('focus', this.value, this.record.model)
    },
    /**
     * 搜索补全项的时候调用
     * @param value
     */
    search (value) {
      this.$emit('search', value, this.record.model)
    },
    /**
     * 被选中时调用，参数为选中项的 value 值
     * @param value
     * @param option
     */
    select (value, option) {
      this.$emit('select', value, this.record.model, option)
    },
    /**
     * 展开下拉菜单的回调
     * @param open
     */
    dropdownVisibleChange (open) {
      this.$emit('dropdownVisibleChange', open, this.record.model)
    }
  }
}
</script>
